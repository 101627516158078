import { Backdrop, Button, CircularProgress, CssBaseline, Dialog, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import LPAppBar from '../../components/LPAppBar';
import LPDrawer from '../../components/LPDrawer';
import Utils from '../../felles/Utils';
import C from '../../felles/UtilsCSS';
import MenuEl from '../../models/MenuEl';
import MenuItemTarget from '../../models/MenuItemTarget';
import UISize from '../../models/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import HelpDialog from './HelpDialog';
import VSEContent from './VSEContent';


const useStyles = makeStyles()((t: Theme) =>
({
  root: { display: 'flex', height: '100%', width: '100vw'/*, border:'5px solid black', overflow: 'hidden'*/, position: 'fixed', },
  content: {
    marginTop: 0,
    flexGrow: 1,
    transition: t.transitions.create('margin', {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.leavingScreen,
    }),
    marginLeft: -340,
    [C.IPAD_10]: { marginTop: -39 },
    [C.SAMSUNG]: { marginTop: -39 },
  },
  contentShift: {
    transition: t.transitions.create('margin', {
      easing: t.transitions.easing.easeOut,
      duration: t.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: { zIndex: t.zIndex.drawer + 1, color: '#fff' },
}));

export default function VSEPage(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuItems: MenuEl[] = useSelector((state: AppState) => state.menuItem.menuItems);
  const urlCodeParam = props.match?.params?.code;
  const location = useLocation();
  const htmlContent: any = useSelector((state: AppState) => state.trans.content);
  const isTransGetInProgress: boolean = useSelector((state: AppState) => state.trans.isTransGetInProgress);
  const isTransPostInProgress: boolean = useSelector((state: AppState) => state.trans.isTransPostInProgress);
  const isTransDialogueOpen: boolean = useSelector((state: AppState) => state.trans.isTransDialogueOpen);
  const isAutoErrorSendingPostInProgress: boolean = useSelector((state) => (state as AppState).support.isAutoErrorSendingPostInProgress);
  const transError: any = useSelector((state: AppState) => state.trans.error);
  const handleDiagClose = () => {
    dispatch(actions.transErrorDialogUpdated(false));
    navigate(Utils.HOMEPAGE_CONTEXT + '/menu');
  }
  const getErrorMsg = () => (transError && transError.error ? transError.error : '');
  const screenSize: UISize = useSelector((state: AppState) => state.ui.screenSize);
  const drawerOpenState: boolean = useSelector((state: AppState) => state.ui.isDrawerOpened);
  const showHelpContent: boolean = useSelector((state: AppState) => state.trans.showHelpContent);

  useEffect(() => {
    document.body.addEventListener('touchmove', Utils.preventDefault, { passive: false });
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
    const TRANS_ID: any = qs.parse(location.search, { ignoreQueryPrefix: true }).transid;
    let _elA = '';
    let _elB = '';
    let _elC = '';
    let _elD = '';
    let _elE = 0;
    let _elEB = 0;
    let _elEBK = 0;
    let _elEBL = 0;
    let _elDHasChildren = false;
    //
    if (!htmlContent) {
      if (menuItems.length > 0)
        menuItems.forEach((elA: MenuEl) => {
          elA.nodes.forEach((elB: MenuEl) => {
            elB.nodes.forEach((elC: MenuEl) => {
              elC.nodes.forEach((elD: MenuEl) => {
                if (elD.code === TRANS_ID) {
                  _elA = elA.name;
                  _elB = elB.name;
                  _elC = elC.name;
                  _elD = elD.description;
                  _elE = elD.tilpasset;
                  _elEB = elD.tilpassetBeta;
                  _elEBK = elD.kodeSynlig;
                  _elEBL = elD.labelSynlig;
                  _elDHasChildren = elD.hasChildren;
                }
              });
            });
          });
        });
        
      if (!isTransGetInProgress) {
        dispatch(actions.transGet(new MenuItemTarget(_elA, _elB, _elC, TRANS_ID, _elE, _elEB, _elEBK, _elEBL, _elDHasChildren, _elD)));
      }
      dispatch(actions.menuItemSetExpanded([_elA]));
      dispatch(actions.menuItemSetSelected([_elA, _elB]));
    }

  }, [dispatch, isTransPostInProgress, isTransGetInProgress, htmlContent, location.search, menuItems, screenSize]);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isTransGetInProgress || isTransPostInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={isTransDialogueOpen || isAutoErrorSendingPostInProgress} onClose={handleDiagClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Beklager, en feil oppsto. Feilrapport er sendt automatisk til Vitec Autodata.<br /><br />{getErrorMsg().name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{getErrorMsg().message}</DialogContentText>
        </DialogContent>
        {!isAutoErrorSendingPostInProgress ?
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '84%', marginLeft: 15, marginTop: 5 }}><Button size={'small'} onClick={() => navigate(Utils.HOMEPAGE_CONTEXT + '/support')} color="primary" style={{ marginRight: 15 }} >
              Send oss mer informasjon.
            </Button>{' '}</div>
            <div><Button style={{ width: '16%', marginBottom: 5 }} onClick={handleDiagClose} color="primary" size={'large'}>Lukk</Button></div>
          </div> : null}
      </Dialog>
      <CssBaseline />
      <LPAppBar ismenuvisible={true} />
      <LPDrawer />
      <main className={drawerOpenState ? classes.contentShift : classes.content}>
        <VSEContent transacId={urlCodeParam} />
        {transError ? transError.name : ''}
      </main>
      {showHelpContent ? <HelpDialog /> : null}
    </div>
  );
}
